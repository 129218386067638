<template>
    <div id="accusation-detail">
        <a-page-header style="border-bottom: 2px solid rgb(235, 237, 240); margin-bottom: 10px;" title="举报详细信息" sub-title="" @back="() => $router.go(-1)" />
        <a-modal v-model:visible="showReject" title="不受理原因" @ok="rejectOK" @cancel="rejectCancel" okText="确认" cancelText="取消" :width="1000">
            <a-row>
                <a-col span="4">请填写不受理理由:</a-col>
                <a-col span="16">
                    <a-textarea v-model:value="rejectReason" placeholder="不受理原因" :rows="4" show-count :maxlength="200" />
                </a-col>
                <a-col span="4" style="padding-left: 5px;">
                    单击快速填入
                    <a-button style="margin-bottom: 5px;" @click="rejectReason += '地址不在辖区范围内'">地址不在辖区范围内</a-button>
                    <a-button style="margin-bottom: 5px;" @click="rejectReason += '地址过于模糊，请重新填写提交'">地址过于模糊</a-button>
                    <a-button style="margin-bottom: 5px;" @click="rejectReason += '类别错误，请重新填写提交'">类别错误</a-button>
                </a-col>
            </a-row>
        </a-modal>
        <div class="blog-detail">
            <a-card :hoverable="true">
                <a-row>
                    <a-col span="3">
                        <a-statistic title="类别" :value="$store.state.category.category[category] ? $store.state.category.category[category] : '未分类'" style="margin-right: 50px" />
                    </a-col>
                    <a-col span="3">
                        <a-statistic title="状态" :value="statusMap[status]" style="margin-right: 50px" :valueStyle="statusStyle" />
                    </a-col>
                    <a-col span="6">
                        <!-- <a-statistic title="是否复议" :value="repeat == 0 ? '初议' : '复议'" style="margin-right: 50px">
                            <template #suffix v-if="repeat == 1">
                                <div style="font-size: large;"><a :href="'/filed-detail/' + RID" target="_blank">点此查看初议信息</a></div>
                            </template>
                        </a-statistic> -->
                    </a-col>
                    <a-col span="6">
                        <a-statistic title="举报人电话" :value="anonymous == 0 ? phone : '匿名提交'" style="margin-right: 50px" groupSeparator="" />
                    </a-col>
                    <a-col span="6">
                        <a-statistic title="提交时间" :value="time" style="margin-right: 50px" />
                    </a-col>
                </a-row>
                <a-row style="margin-top: 5px;">
                    <a-col span="24">
                        <a-statistic title="举报地点" :value="location" style="margin-right: 50px" />
                    </a-col>
                </a-row>
            </a-card>
        </div>
        <a-row>
            <a-col span="16" style="padding-right: 5px; text-align: left;">
                <a-card title="举报内容" :hoverable="true">
                    <div style="min-height: 40vh; font-size: large; color: rgb(95, 95, 95);">
                        {{issue}}
                    </div>
                </a-card>
            </a-col>
            <a-col span="8" style="padding-left: 5px; text-align: left;">
                <a-card title="举报图片" :hoverable="true">
                    <div class="img-grid">
                        <div class="img-item" v-for="(img,index) in imgs" :key='img'>
                            <a-card class="square">
                                <div class="img-container center">
                                    <a-image class="min" :src="'/imgs/' + img" width="" />
                                </div>
                                <div class="download">
                                    <a :href="img" :download="location + '图片' + index">下载</a>
                                </div>
                            </a-card>
                        </div>
                    </div>
                </a-card>
            </a-col>
        </a-row>
        <a-divider />
        <a-row style="text-align: left;">
            <a-col span="10" class="center">
                <div style="font-size: large;" v-if="$store.state.userInfo.userInfo.permission == 1">状态：{{statusMap[status]}}</div>
                <div style="font-size: large;" v-else-if="$store.state.userInfo.userInfo.permission == 2">
                    状态：<a-cascader v-model:value="status" :options="statusOptions" :allowClear="false" placeholder="请设置状态" @change="statusChange" />
                </div>
            </a-col>
            <a-col span="4" offset="6" v-if="status == 100">
                <a-button type="primary" size="large" @click="admit">受理</a-button>
            </a-col>
            <a-col span="4" v-if="status == 100">
                <a-button type="danger" size="large" @click="onReject">不受理</a-button>
            </a-col>
        </a-row>
        <a-row style="margin-top: 24px; text-align: left;">
            <a-col span="16" style="padding-right: 5px; text-align: left;">
                <a-card title="填写反馈内容" :hoverable="true">
                    <div style="font-size: large;">
                        <a-textarea v-model:value="newFeedback" placeholder="反馈内容" :rows="4" show-count :maxlength="200" :disabled="status != 101" />
                    </div>
                    <div style="margin-top: 10px;">
                        <a-button type="primary" @click="add_feedback" :disabled="status != 101">发送反馈</a-button>
                    </div>
                    <div style="margin-top: 10px;">
                        <a-button type="primary" @click="add_finish" :disabled="status != 101">发送反馈且完成</a-button>
                    </div>
                </a-card>
            </a-col>
            <a-col span="8" style="padding-left: 5px; text-align: left;">
                <a-card title="历史反馈" :hoverable="true">
                    <div style="font-size: large;">
                        <a-collapse v-if="feedbacks.length > 0">
                            <a-collapse-panel v-for="feedback in feedbacks" :key="feedback.title" :header="feedback.title + ' - ' + feedback.time">
                                <p>{{ feedback.content }}</p>
                            </a-collapse-panel>
                        </a-collapse>
                        <a-empty v-else>
                            <template #description>
                                暂无反馈
                            </template>
                        </a-empty>
                    </div>
                </a-card>
            </a-col>
        </a-row>
        <a-divider />
        <!-- <a-row>
            <a-col span="2" style="text-align: left;">
                <a @click="" :href="'/accusation-detail/' + prevItem" v-if="prevItem">⬅前一项</a>
                <p v-else>已是第一项</p>
            </a-col>
            <a-col span="2" offset="20" style="text-align: right;">
                <a @click="" :href="'/accusation-detail/' + nextItem" v-if="nextItem">下一项➡</a>
                <p v-else>已是最后一项</p>
            </a-col>
        </a-row> -->
    </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import axios from "axios";
import moment from "moment";
export default {
    methods: { },
    data () {
        return { };
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        // const ID = ref(route.params.accusation_id);
        const ID = ref(route.params.accusation_id);

        const UID = ref('');
        const anonymous = ref(0);
        const category = ref(1);
        const type = ref(0);
        const time = ref(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
        const location = ref('');
        const issue = ref('');
        const longitude = ref(0);
        const latitude = ref(0);
        const ip = ref('');
        const imgs = ref([
            'https://s3.bmp.ovh/imgs/2022/08/09/795de76021e11aea.jpeg',
            'https://s3.bmp.ovh/imgs/2022/08/09/795de76021e11aea.jpeg',
            'https://s3.bmp.ovh/imgs/2022/08/09/795de76021e11aea.jpeg',
            'https://s3.bmp.ovh/imgs/2022/08/09/795de76021e11aea.jpeg'
            ]);
        const status = ref(100);
        const repeat = ref(1);
        const RID = ref('');
        const phone = ref('');
        const feedbacks = ref([ ]);
        const prevItem = ref('');
        const nextItem = ref('2');
        const statusStyle = computed(() => {
            if (status.value == 0) {
                return {color: '#fe4d55'};
            } else if (status.value == 1) {
                return {color: '#fdb927'};
            } else if (status.value == 100) {
                return {color: '#ffdb10'};
            } else if (status.value == 101) {
                return {color: 'rgb(0, 204, 253)'};
            } else if (status.value == 102) {
                return {color: '#4cdc71'};
            } else {
                return {color: 'black'};
            }
        });

        const newFeedback = ref('');

        const categoryMap = ref(['', '食品', '药品', '计量', '电梯', '虚假广告']);
        const statusMap = ref({
            0: '不受理',
            1: '用户撤回',
            100: '已提交',
            101: '已受理',
            102: '已完成'
        });
        const statusOptions = ref([
            {
                value: 0,
                label: '不受理'
            },
            {
                value: 100,
                label: '已提交'
            },
            {
                value: 101,
                label: '已受理'
            },
            {
                value: 102,
                label: '已完成'
            }
        ]);

        const showReject = ref(false);
        const rejectReason = ref('');

        function onReject() {
            rejectReason.value = '';
            showReject.value = true;
        }

        function rejectOK() {
            axios
                .post("/api/server/setCompliantStatus", {
                    id: ID.value,
                    status: 0,
                    feedback: {
                        title: '举报不受理',
                        content: '不受理原因：' + rejectReason.value
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        message.success('处理成功');
                        showReject.value = false;
                    } else {
                        message.error('处理失败：' + res.data.msg);
                    }
                });
        }

        function rejectCancel() {
            rejectReason.value = '';
        }

        function statusChange(value, e) {
            console.log('status change', value);
            axios
                .post("/api/server/setCompliantStatus", {
                    id: ID.value,
                    status: value[0]
                })
                .then((res) => {
                    if (res.data.success) {
                        message.success('处理成功');
                    } else {
                        message.error('处理失败：' + res.data.msg);
                    }
                });
        }

        function getDetail() {
            axios
                .post("/api/server/getComplaint", {
                    id: ID.value
                })
                .then((res) => {
                    console.log(res.data);
                    if (res.data.errcode == 100) {
                        category.value = res.data.data.complaint.category;
                        anonymous.value = res.data.data.complaint.anonymous;
                        type.value = res.data.data.complaint.type;
                        time.value = res.data.data.complaint.time;
                        location.value = res.data.data.complaint.location;
                        issue.value = res.data.data.complaint.issue;
                        longitude.value = res.data.data.complaint.longitude;
                        latitude.value = res.data.data.complaint.latitude;
                        ip.value = res.data.data.complaint.ip;
                        // imgs.value = res.data.data.complaint.imgs;
                        status.value = res.data.data.complaint.status;
                        repeat.value = res.data.data.complaint.repeat;
                        RID.value = res.data.data.complaint.rid;
                        phone.value = res.data.data.userphone;
                        feedbacks.value = res.data.data.feedbacks;
                        imgs.value = res.data.data.complaint.imgs.split(',');
                    }
                });
        }

        function add_feedback() {
            axios
                .post("/api/server/addFeedback", {
                    cid: ID.value,
                    time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                    title: '投诉反馈',
                    content: newFeedback.value
                })
                .then((res) => {
                    if (res.data.success) {
                        message.success('处理成功');
                        newFeedback.value = '';
                        getDetail();
                    } else {
                        message.error('处理失败：' + res.data.msg);
                    }
                });
        }

        function add_finish() {
            axios
                .post("/api/server/setCompliantStatus", {
                    id: ID.value,
                    status: 102,
                    feedback: {
                        title: '已完成',
                        content: newFeedback.value
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        message.success('处理成功');
                        newFeedback.value = '';
                        getDetail();
                    } else {
                        message.error('处理失败：' + res.data.msg);
                    }
                });
        }

        function admit() {
            axios
                .post("/api/server/setCompliantStatus", {
                    id: ID.value,
                    status: 101,
                    feedback: {
                        title: '已受理',
                        content: '举报已受理'
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        message.success('处理成功');
                        newFeedback.value = '';
                        getDetail();
                    } else {
                        message.error('处理失败：' + res.data.msg);
                    }
                });
        }

        onMounted(() => {
            store.dispatch({ type: 'setSelectedKeys', selectedKeys: ['Accusations'] });
            store.dispatch({ type: 'setOpenKeys', openKeys: ['Manage'] });
            getDetail();
        });

        return {
            ID,
            UID,
            anonymous,
            category,
            type,
            time,
            location,
            issue,
            longitude,
            latitude,
            ip,
            imgs,
            status,
            repeat,
            RID,
            phone,
            feedbacks,
            prevItem,
            nextItem,
            statusStyle,
            newFeedback,
            categoryMap,
            statusMap,
            statusOptions,
            showReject,
            rejectReason,
            onReject,
            rejectOK,
            rejectCancel,
            statusChange,
            add_feedback,
            add_finish,
            admit
        }
    }
}
</script>

<style>
#accusation-detail {
    width: 100%;
    text-align: center;
}

.rows {
    margin-bottom: 10px;
    height: 30px;
    font-size: 15px;
    border-bottom: 1px solid rgb(235, 237, 240);
}

.blog-detail {
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

:root {
    --img-size: 0
}

.img-grid {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.img-item {
    display: flex;
    justify-content: center;
    width: 30%;
    margin: 1% 1%;
    /* height: 20vh; */
    /* height: 0; */
    /* padding-bottom: 23%; */
    overflow: hidden;
    border-radius: 10px;
}

.square {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

.min {
    width: 100%;
    /* height: 10vh; */
}

.img-container {
    /* padding: 10px; */
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    overflow: hidden;
}

.download {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 5px;
    left: 0;;
}
</style>
